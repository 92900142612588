import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleVideoModal } from "../../redux/actions/miscActions";
import { Modal } from "antd";

export class VideoModal extends Component {
  handleCancel = () => {
    const data = {
      bool: false,
      video: "",
    };
    this.props.toggleVideoModal(data);
  };

  render() {
    const { showVideoModal, video } = this.props.misc;
    return (
      <div className="video-modal-page">
        <Modal
          visible={showVideoModal}
          closable={false}
          centered={true}
          footer={null}
          maskClosable={true}
          className="video-modal"
          onCancel={this.handleCancel}
        >
          {video === "1" ? (
            <iframe
              title="video_1"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FHintzenAdministrationConsulting%2Fvideos%2F2429085637190705%2F&show_text=0&width=560"
              scrolling={"no"}
              frameborder={0}
              allowTransparency={true}
              allowFullScreen={true}
              autoPlay={true}
            ></iframe>
          ) : video === "2" ? (
            <iframe
              title="video_2"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FHintzenAdministrationConsulting%2Fvideos%2F1007431182935048%2F&show_text=0&width=560"
              scrolling={"no"}
              frameborder={0}
              allowTransparency={true}
              allowFullScreen={true}
            ></iframe>
          ) : video === "3" ? (
            <iframe
              title="video_3"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FHintzenAdministrationConsulting%2Fvideos%2F2448922475435702%2F&show_text=0&width=560"
              scrolling={"no"}
              frameborder={0}
              allowTransparency={true}
              allowFullScreen={true}
              autoPlay={true}
            ></iframe>
          ) : video === "4" ? (
            <iframe
              title="video_4"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FHintzenAdministrationConsulting%2Fvideos%2F413422639333587%2F&show_text=0&width=560"
              scrolling={"no"}
              frameborder={0}
              allowTransparency={true}
              allowFullScreen={true}
              autoPlay={true}
            ></iframe>
          ) : null}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  misc: state.misc,
});

export default connect(mapStateToProps, { toggleVideoModal })(VideoModal);

import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleVideoModal, resetResult } from "../redux/actions/miscActions";
import Groot from "./Groot";
import Klein from "./Klein";
import MiddelGroot from "./MiddelGroot";
import OOB from "./OOB";
import ContactForm from "./utils/ContactForm";

export class Results extends Component {
  handleVideo = (video) => {
    const data = {
      bool: true,
      video: video,
    };
    this.props.toggleVideoModal(data);
  };

  componentWillUnmount = () => {
    this.props.resetResult();
  };

  render() {
    const { rechtspersoon } = this.props.misc;
    return (
      <div className="results-page">
        <div className="left">
          {rechtspersoon === "klein" ? (
            <Klein />
          ) : rechtspersoon === "groot" ? (
            <Groot />
          ) : rechtspersoon === "middelgroot" ? (
            <MiddelGroot />
          ) : rechtspersoon === "oob" ? (
            <OOB />
          ) : null}
          <hr />
          {rechtspersoon !== false ? <ContactForm /> : null}
        </div>
        <div className="right">
          <div className="side-section">
            <h4>Why is IFRS necessary?</h4>
            <p>
              Under IFRS, companies will have a standardized and consistent set
              of accounting and produce financial reports to meet local legal
              and fixed consolidated requirements. This will help improve the
              analysis of financial reporting and tax planning processes.
            </p>
            {/* <button>Read more</button> */}
          </div>
          <div className="side-section">
            <h4>Our IFRS video series</h4>
            <p>Our 4 part video series explaining the importance of IFRS</p>
            <div className="videos">
              <div className="video" onClick={() => this.handleVideo("1")}>
                <img src={require("../assets/images/wat_is_ifrs.png")} alt="" />
              </div>

              <div className="video" onClick={() => this.handleVideo("2")}>
                <img
                  src={require("../assets/images/voor_wie_is_ifrs.png")}
                  alt=""
                />
              </div>

              <div className="video" onClick={() => this.handleVideo("3")}>
                <img src={require("../assets/images/warom_ifrs.png")} alt="" />
              </div>

              <div className="video" onClick={() => this.handleVideo("4")}>
                <img
                  src={require("../assets/images/de_gevolgen_voor_u.png")}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="side-section">
            <h4>More resources</h4>
            <ul>
              <li>
                <a
                  href="http://www.dna.sr/media/192917/sb_2017___84_wet_op_de_jaarrekening.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icofont-download"></i>
                  Download the Surinamese Law of Financial Statements (NL)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  misc: state.misc,
});

export default connect(mapStateToProps, { toggleVideoModal, resetResult })(
  Results
);
